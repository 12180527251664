import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import User from './modules/users'
import Xuanke from './modules/xuanke'
import Url from './modules/url'
import Think from './modules/think'

export default new Vuex.Store({
  modules: {
    User, Xuanke,Url,Think
  },
})
