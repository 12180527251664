import { Service } from '@/utils/Service'

// 登录
export function login (data) {
  return Service({
    url: '/login/',
    data: data
  })
}

// 检查是否登录--同步
export function checklogin (data) {
  return Service({
    url: '/checklogin/',
    data: data
  })
}

// 退出登录
export function loginout (data) {
  return Service({
    url: '/logout/',
    data: data
  })
}

// 查询用户填过的信息
export function infoquery (data) {
  return Service({
    url: '/complement/',
    data: data
  })
}

// 发送个人信息
export function PersonalInfo (data) {
  return Service({
    url: '/personalinfo/',
    data: data
  })
}

// 免责同意
export function agreeliability (data) {
  return Service({
    url: '/agreeliability/',
    data: data
  })
}

// 首次登录修改密码
export function FirstLoginResetPwd (data) {
  return Service({
    url: '/firstloginresetpwd/',
    data: data
  })
}
