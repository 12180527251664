/*
* url info
*/
export default {
    namespaced: true,
    state: () => ({
        app: '127.0.0.1:8000',
        baseUrl:'https://career-explore.com/Public/',
    }),
    actions: {
    },
    getters: {
    },
    mutations: {
    },
  }
  