import axios from 'axios'
import { Message, Loading } from 'element-ui'
import State from './../store/index'
// const ConfigBaseURL = State.state.Api.baseUrl //默认路径
// console.log('baseurl in vuex ', ConfigBaseURL);
let loadingInstance = null //这里是loading
let baseUrl = ''
if (process.env.VUE_APP_ENVIRONMENT === 'productionEnv') {
  baseUrl = 'https://xingaokao.career-explore.com/evalSelect'
} else {
  baseUrl = '/evalSelect'
}

//使用create方法创建axios实例
export const Service = axios.create({
  timeout: 10 * 1000, // 请求超时时间
  // baseURL: ConfigBaseURL,
  baseURL: baseUrl,
  // withCredentials: true, // 允许跨域请求携带cookie
  method: 'POST',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

// 添加请求拦截器
Service.interceptors.request.use(config => {
  loadingInstance = Loading.service({
    lock: true,
    text: '加载中...',
    customClass: 'lj-loading' // *这里设置他的class名称,这里最重要
  })

  // 在这里添加token
  let token = sessionStorage.getItem('token')
  if (token == null) return config
  else {
    Service.defaults.headers['token'] = token
    return config
  }
})

// 添加响应拦截器
Service.interceptors.response.use(
  response => {
    // 状态码为2/3开头
    loadingInstance.close()
    return response.data
  },
  error => {
    // 状态码为4/5开头的进来这里
    // if (error.response.status === 401) {
    //   // 身份过期/token无效 清空token
    // }
    console.log('TCL: error', error)
    let msg = error.Message !== undefined ? error.Message : ''
    Message({
      message: '网络错误' + msg,
      type: 'error',
      duration: 3 * 1000
    })
    loadingInstance.close()
    return Promise.reject(error)
  }
)
