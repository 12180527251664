<template>
  <div id="app" style="width: 100%; height: 100%">
    <router-view />
  </div>
</template>

<script>
var _hmt = _hmt || []
;(function () {
  var hm = document.createElement('script')
  hm.src = 'https://hm.baidu.com/hm.js?5ca1e98131f5233c03d625b30305a73b'
  var s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(hm, s)
})()

var setRem = function () {
  var designRem = 10
  var designWidth = 1280
  var designWidthMobile = 375
  var currentWidth = window.innerWidth
  var currentHeight = window.innerHeight
  var currentRem =
    currentWidth > currentHeight
      ? (designRem * currentWidth) / designWidth
      : (designRem * currentWidth) / designWidthMobile
  document.getElementsByTagName('html')[0].style.fontSize = currentRem + 'px'
}
setRem()
window.onresize = setRem

export default {
  name: 'App',
  computed: {
    isLoggedIn: function () {
      return this.$store.state.User.haslogin
    },
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  provide () {
    // 使用函数的形式，可以访问到 `this`
    return {
      isLoggedIn: this.isLoggedIn,
      isMobile: this.isMobile
    }
  },
  mounted: function () {
    if (this.isLoggedIn == false) {
      this.$router.replace({
        path: '/login'
      })
    }
  },
  created () {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem('store'))
        )
      )
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  }
}
</script>

<style lang="less">
@import url('./Public/utils/new-index/css/bootstrap.css');
@import url('./Public/utils/new-index/css/font-awesome.css');
@import url('./Public/utils/new-index/css/style.css');
@import url('./Public/resources/css/components/base.min.css');
@import url('./Public/resources/css/components/icon.min.css');
@import url('./Public/resources/css/components/modal.min.css');
@import url('./Public/resources/css/components/nav.min.css');
@import url('./Public/resources/css/components/footer.min.css');
@import url('./Public/resources/css/components/close.min.css');
@import url('./Public/resources/css/components/alert.min.css');
@import url('./Public/resources/css/components/button.min.css');
@import url('./Public/resources/css/components/form.min.css');
@import url('./Public/resources/css/components/utility.min.css');
@import url('./Public/resources/css/components/table.min.css');
.lj-loading {
  .el-loading-spinner {
    .el-loading-text {
      color: #f17c67;
      margin: 1rem 0;
      font-size: 2rem;
      text-align: center;
    }
    .circular {
      height: 15%;
      width: 15%;
      .path {
        stroke: #f17c67;
      }
    }
  }
}

/deep/ .el-loading-spinner #app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

html,
body {
  height: 100%;
  width: 100%;
  border: 0;
  margin: 0;
  padding: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

a {
  text-decoration: none;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgb(220, 220, 220);
  --webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(220, 220, 220, 0.4);
}
</style>
