import Vue from 'vue'
import VueRouter from 'vue-router'
import State from './../store/index'
import { Message, Loading } from 'element-ui'
import { checklogin } from './../api/userinfo'
Vue.use(VueRouter)

const routes = [
  {
    // Home首页
    path: '/',
    name: 'HomeIndexIndex',
    meta: { loginIn: true, identity: '' },
    component: () => import('./../views/Home/Index/index.vue')
  },
  {
    // 登录页面
    path: '/login',
    name: 'LoginIndex',
    component: () => import('./../views/Home/Index/login.vue')
  },
  {
    // 重置密码
    path: '/resetpwd',
    name: 'resetpwd',
    meta: { loginIn: true, identity: '' },
    component: () => import('./../views/Home/Index/resetPwd.vue')
  },

  /**
   * 介绍 part
   */
  {
    // 首页-介绍
    path: '/HomeIndexNewFeature',
    component: () => import('./../views/Home/Index/Introduction/index.vue'),
    children: [
      {
        // 首页-产品特色
        path: '/',
        name: 'HomeIndexNewFeature',
        meta: { loginIn: true, identity: '' },
        component: () =>
          import('../views/Home/Index/Introduction/newfeatures.vue')
      },
      {
        // 首页-产品价格
        path: '/HomeIndexNewPrices',
        name: 'HomeIndexNewPrices',
        meta: { loginIn: true, identity: '' },
        component: () =>
          import('../views/Home/Index/Introduction/newprices.vue')
      },
      {
        // 首页-合作伙伴
        path: '/HomeIndexNewCooperation',
        name: 'HomeIndexNewCooperation',
        meta: { loginIn: true, identity: '' },
        component: () =>
          import('../views/Home/Index/Introduction/newcooperation.vue')
      },
      {
        // 首页-产品列表
        path: '/HomeIndexNewProducts',
        name: 'HomeIndexNewProducts',
        meta: { loginIn: true, identity: '' },
        component: () =>
          import('../views/Home/Index/Introduction/newproducts.vue')
      },
      {
        // 首页-使用步骤
        path: '/HomeIndexNewCooperationInst',
        name: 'HomeIndexNewCooperationInst',
        meta: { loginIn: true, identity: '' },
        component: () =>
          import('../views/Home/Index/Introduction/newcooperation_inst.vue')
      },
      {
        // 首页-主要成员介绍
        path: '/HomeIndexMainMembers',
        name: 'HomeIndexMainMembers',
        meta: { loginIn: true, identity: '' },
        component: () =>
          import('../views/Home/Index/Introduction/main_members.vue')
      },
      {
        // 进入学科能力测评系统--使用简介
        path: '/HomeIndexNewProductsManual',
        name: 'HomeIndexNewProductsManual',
        meta: { loginIn: true, identity: '' },
        component: () =>
          import('../views/Home/Index/Introduction/newproducts_manual.vue')
      }
    ]
  },

  /**
   * 三个进入具体页面的按钮
   */
  {
    // 进入首页
    path: '/HomeIndexSubjectindex',
    component: () => import('./../views/Home/Index/enter/index.vue'),
    children: [
      {
        // 进入学科能力测评系统
        path: '/',
        name: 'HomeIndexSubjectindex',
        meta: { loginIn: true, identity: '' },
        component: () => import('../views/Home/Index/enter/subjectindex.vue')
      },
      {
        // 进入专业测评系统
        path: '/HomeIndexMajorIndex',
        name: 'HomeIndexMajorIndex',
        meta: { loginIn: true, identity: '' },
        component: () => import('../views/Home/Index/enter/majorindex.vue')
      },
      {
        // 进入选科模块
        path: '/HomeIndexXuankeIndex',
        name: 'HomeIndexXuankeIndex',
        meta: { loginIn: true, identity: '' },
        component: () => import('../views/Home/Index/enter/xuankeindex.vue')
      }
    ]
  },

  /**
   * 学科倾向 专业测评part
   */
  {
    path: '/HomeMajorIndex',
    component: () => import('../views/Home/Major/index.vue'),
    children: [
      {
        // 做题的页面
        path: '/HomeMajorStdMatchPage',
        name: 'HomeMajorStdMatchPage',
        meta: { loginIn: true, identity: '' },
        component: () => import('../views/Home/Major/problem.vue')
      },
      /**
       * 报告part
       */
      {
        // 职业报告倾向
        path: '/HomeMajorStdResultPage1',
        name: 'HomeMajorStdResultPage1',
        meta: { loginIn: true, identity: '' },
        component: () => import('../views/Home/Major/result/std_result_1.vue')
      },
      {
        // 人格报告倾向
        path: '/HomeMajorStdResultPage2',
        name: 'HomeMajorStdResultPage2',
        meta: { loginIn: true, identity: '' },
        component: () => import('../views/Home/Major/result/std_result_2.vue')
      },
      {
        // 创新素质指数报告
        path: '/HomeMajorStdResultPage3',
        name: 'HomeMajorStdResultPage3',
        meta: { loginIn: true, identity: '' },
        component: () => import('../views/Home/Major/result/std_result_3.vue')
      },
      {
        // 学科优势测评报告
        path: '/HomeMajorStdResultPage4',
        name: 'HomeMajorStdResultPage4',
        meta: { loginIn: true, identity: '' },
        component: () => import('./../views/Home/Major/result/std_result_4.vue')
      },
      {
        // 一、高校大类专业匹配全景报告
        path: '/HomeMajorStdResultPage5',
        name: 'HomeMajorStdResultPage5',
        meta: { loginIn: true, identity: '' },
        component: () => import('../views/Home/Major/result/std_result_5.vue')
      },
      {
        // 二、最适合的高校大类专业详细报告
        path: '/HomeMajorStdResultPage6',
        name: 'HomeMajorStdResultPage6',
        meta: { loginIn: true, identity: '' },
        component: () => import('../views/Home/Major/result/std_result_6.vue')
      },
      {
        // 三、最适合的高校大类专业内小类专业排序报告
        path: '/HomeMajorStdResultPage7',
        name: 'HomeMajorStdResultPage7',
        meta: { loginIn: true, identity: '' },
        component: () => import('./../views/Home/Major/result/std_result_7.vue')
      },
      {
        // 职业报告倾向--mobile
        path: '/HomeMajorStdResultPage1Mobile',
        name: 'HomeMajorStdResultPage1Mobile',
        meta: { loginIn: true, identity: '' },
        component: () =>
          import('../views/Home/Major/result/mobile_std_result_1.vue')
      },
      {
        // 人格报告倾向--mobile
        path: '/HomeMajorStdResultPage2Mobile',
        name: 'HomeMajorStdResultPage2Mobile',
        meta: { loginIn: true, identity: '' },
        component: () =>
          import('../views/Home/Major/result/mobile_std_result_2.vue')
      },
      {
        // 创新素质指数报告--mobile
        path: '/HomeMajorStdResultPage3Mobile',
        name: 'HomeMajorStdResultPage3Mobile',
        meta: { loginIn: true, identity: '' },
        component: () =>
          import('../views/Home/Major/result/mobile_std_result_3.vue')
      },
      {
        // 学科优势报告--mobile
        path: '/HomeMajorStdResultPage4Mobile',
        name: 'HomeMajorStdResultPage4Mobile',
        meta: { loginIn: true, identity: '' },
        component: () =>
          import('../views/Home/Major/result/mobile_std_result_4.vue')
      },
      {
        // 高校大类专业匹配全景报告--mobile
        path: '/HomeMajorStdResultPage5Mobile',
        name: 'HomeMajorStdResultPage5Mobile',
        meta: { loginIn: true, identity: '' },
        component: () =>
          import('../views/Home/Major/result/mobile_std_result_5.vue')
      }
    ]
  },
  {
    // 报告打印
    path: '/HomeMajorStdPrint',
    name: 'HomeMajorStdPrint',
    meta: { loginIn: true, identity: '' },
    component: () => import('../views/Home/Major/result/std_print.vue')
  },

  /**
   * 学科能力测试 part
   */
  {
    path: '/HomeTestIndex',
    component: () => import('./../views/Home/Subject/index.vue'),
    children: [
      {
        // 测评导航
        path: '/',
        name: 'HomeTestIndex',
        meta: { loginIn: true, identity: '' },
        component: () => import('./../views/Home/Subject/content.vue')
      },
      {
        // 答题
        path: '/HomeTestProblem',
        name: 'HomeTestProblem',
        meta: { loginIn: true, identity: '' },
        component: () => import('./../views/Home/Subject/problem.vue')
      },
      {
        // 学科能力测评报告
        path: '/HomeTestResult',
        name: 'HomeTestResult',
        meta: { loginIn: true, identity: '' },
        component: () => import('./../views/Home/Subject/result.vue')
      }
    ]
  },

  /**
   * 俩新加报告
   */
  {
    path: '/matchrequirements',
    component: () => import('./../views/Home/Report/index.vue'),
    children: [
      {
        // 二十个最匹配专业选科要求
        path: '/',
        name: 'MatchRequirements',
        meta: { loginIn: true, identity: '' },
        component: () => import('./../views/Home/Report/matchRequirements.vue')
      },
      {
        // 考试成绩和排名
        path: '/scoreandrank',
        name: 'scoreandrank',
        meta: { loginIn: true, identity: '' },
        component: () => import('./../views/Home/Report/scoreRank.vue')
      },
      {
        // 选科推荐报告
        path: '/xuankerecommand',
        name: 'xuankerecommand',
        meta: { loginIn: true, identity: '' },
        component: () => import('./../views/Home/Report/xuankeRecommand.vue')
      }
    ]
  },

  /**
   * 新高考part
   */
  {
    path: '/HomeIndexXingaokaoIndex',
    component: () => import('./../views/xingaokao/Gaokao/index.vue'),
    children: [
      {
        // 学校详情--college---就是看录取分数，录取人数等等
        path: '/college',
        name: 'SchoolDetailPage',
        meta: { loginIn: true, identity: '' },
        component: () => import('./../views/xingaokao/Gaokao/School/Index')
      },
      {
        // 查看专业信息--右边有模拟选科的按钮
        path: '/major',
        name: 'courseRequire',
        meta: { loginIn: true, identity: '' },
        component: () => import('./../views/xingaokao/Gaokao/courseRequire.vue')
      },
      {
        // 模拟选科的页面
        path: '/monixuanke',
        name: 'xuanke',
        meta: { loginIn: true, identity: '' },
        component: () => import('./../views/xingaokao/Gaokao/xuanke.vue')
      }
    ]
  },

  /**
   * 咨询
   */
  {
    // ConsultUs-咨询
    path: '/ConsultUs',
    name: 'ConsultUs',
    meta: { loginIn: true, identity: '' },
    component: () => import('./../views/Home/Consult/consultor.vue')
  },
  {
    // Consultor-咨询师
    path: '/Consultor',
    name: 'Consultor',
    meta: { loginIn: true, identity: '' },
    component: () => import('./../views/Home/Consult/index.vue')
    
  },
  /**
   * 整体的报告中心
   */
  {
    // 报告中心导航目录
    path: '/reportCenter',
    name: 'reportCenter',
    meta: { loginIn: true, identity: '' },
    component: () => import('./../views/Home/Index/reportCenter.vue')
  },
  {
    // 报告中心导航目录--移动设备
    path: '/reportCenterMobile',
    name: 'reportCenterMobile',
    meta: { loginIn: true, identity: '' },
    component: () => import('./../views/Home/Index/reportCenterMobile.vue')
  },
  /**
   * 404 page
   */
  {
    path: '*',
    name: 'nofound',
    meta: { loginIn: null, identity: '' },
    component: () => import('./../views/404page/404page.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'router-link-active'
})

// 添加导航守卫 每一个都检查
router.beforeEach(async (to, from, next) => {
  let res = await checklogin({
    identity: 'student'
  })
  // console.log('checklogin', res)
  let haslogin = res.haslogin
  let identity = res.identity
  let InfoCompleted = res.InfoCompleted != 0
  let passwordchanged = res.passwordchanged != 0
  if (to.meta.loginIn && !haslogin) {
    // 这些路由都需要登录，但如果没登录的话就重定向到首页
    Message({
      message: '您尚未登陆，无法访问',
      type: 'error',
      duration: 2 * 1000
    })
    return { name: 'LoginIndex' }
  } else if (
    to.meta.loginIn &&
    haslogin &&
    (!InfoCompleted || !passwordchanged)
  ) {
    console.log('需要修改密码和完善个人信息')
    next({ name: 'LoginIndex' })
  } else {
    next()
  }
})

export default router
