/*
 * 模拟选科的内容
 */
export default {
  namespaced: true,
  state: () => ({
    currentStep: 0,
    classname: '',
    enrollyear: '',
    gender: '',
    phonenumber: '',
    schoolloccity: '',
    schoolloccounty: '',
    schoollocprovince: '',
    schoolname: '',
    studentidnum: '',
    username: null,
    name: null,
    choose1from2: 'physics_require',
    choose2from4: ['chemistry_require', 'biology_require']
  }),
  actions: {},
  getters: {},
  mutations: {
    changeCurrentStep: function (state, newStep) {
      state.currentStep = newStep
      console.log('new step', state.currentStep)
    },
    changeInfo: function (state, param) {
      state.classname = param[0]
      state.enrollyear = param[1]
      state.gender = param[2]
      state.schoolloccity = param[3]
      state.schoolloccounty = param[4]
      state.schoollocprovince = param[5]
      state.schoolname = param[6]
      state.studentidnum = param[7]
      state.username = param[8]
    },
    change1from2: function (state, param) {
      state.change1from2 = param
    },
    change2from4: function (state, param) {
      state.change2from4 = param
    }
  }
}
